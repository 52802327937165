import React from "react"
import { graphql } from "gatsby"
import $ from "jquery"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import T_TOP_BANNER from "../components/t_top_banner"
import T_TOP_CONCEPT from "../components/t_top_concept"
import T_TOP_HOWIT from "../components/t_top_howit"
import T_TOP_PRODUCTS from "../components/t_top_products"
// import T_TOP_METHOD from "../components/t_top_method"
// import T_TOP_INTERVIEW from "../components/t_top_interview"
// import T_TOP_EYECREAM from "../components/t_top_eyecream";
// import T_TOP_VISIT from "../components/t_top_visit"
// import T_TOP_MEDIA from "../components/t_top_media"
// import T_TOP_INFO from "../components/t_top_info"
// import P_DUMMY from "../components/p_dummy"
import T_TOP_INFO_WP from "../components/t_top_info_wp"

const IndexPage = ({ data }) => {
  const quizUrl = process.env.QUESTION_URL_INTEGRATED_FORM;
  const fvCtaUrl = process.env.QUESTION_URL_INTEGRATED_FORM;
  const loginUrl = process.env.LOGIN_URL;
  if (typeof window !== `undefined`) {
    window.onload = function() {
      if (typeof document !== `undefined`) {
        function fixAct() {
          var windowTop = $(window).scrollTop()
          var windowHeight = $(window).innerHeight()
          var start = $("#float-start").offset().top - windowHeight
          var end = $("#float-end").offset().top - windowHeight
          if (windowTop > start && windowTop < end) {
            $("#js-cta-float").addClass("show")
          } else {
            $("#js-cta-float").removeClass("show")
          }
        }
        if (document.location.pathname == "/") {
          $(window).on("scroll", fixAct)
        }
      }
    }
  }

  return (
    <Layout
      sectionClasses={"t-top js-top"}
      dataNamespace={"top"}
      isHeadLogoWhite={true}
      isCtaFloat={true}
    >
      <SEO title="HOTARU PERSONALIZED" />
      {/*今後復活させる可能性あるので残しておく*/}
      <div className="t-top__opening p-opening js-opening">
        <div className="p-opening__bg js-opening__bg" />
        <div className="p-opening__logo js-opening__logo">
          <img src={'/images/common/c-logo-tac--black.svg'} alt="HOTARU PERSONALIZED" />
        </div>
      </div>
      <div className="t-top__article">
        {/*今後別の用途で使う可能性あるので残しておく*/}
        {/* <a href={"https://hotaru-personalized.com/news/176"}><div className="t-top__head__label">HOTARU PERSONAlIZED POPUP STOREが渋谷スクランブルスクエアにオープン！</div></a> */}
        <div className="c-heroHeader">
          <div className="c-heroHeader__bg js-heroHeader__bg">
            {/* FvCarouselが入る */}
              <div className="js-top__fvSlider">
            <div className="c-heroHeader__bg__slide slide__1">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_all.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_all.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            <div className="c-heroHeader__bg__slide slide__2">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_pink.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_pink.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            <div className="c-heroHeader__bg__slide slide__3">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_skyblue.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_skyblue.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            <div className="c-heroHeader__bg__slide slide__4">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_blue.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_blue.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            <div className="c-heroHeader__bg__slide slide__5">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_red.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_red.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            <div className="c-heroHeader__bg__slide slide__6">
              <picture>
                <source media="(max-width:959px)" srcSet={"/images/top/sp/fv_purple.jpg?v=202108022200"} />
                <img className="js-fitimg js__kv__sld" src={"/images/top/pc/fv_purple.jpg?v=202108022200"} alt="" />
              </picture>
            </div>
            </div>
            <div className="c-heroHeader__fv_absolute">
              <div className="c-heroHeader__fv_absolute__center">
                  <h1>PERSONALIZED</h1>
                  <p>Skin care.</p>
              </div>
            </div>
            <div className="c-heroHeader__slider">
              <div className="pages"><span className="count-current">1</span>/6</div>
              <hr />
              {/* ページ送りボタンが入る */}
            </div>
          </div>
          <div className={"c-heroHeader__cta"}>
            {/*<div className={"c-heroHeader__cta__ttl"}>*/}
            {/*  PERSONALIZE*/}
            {/*  <br />*/}
            {/*  YOUR SKIN CARE*/}
            {/*</div>*/}
            {/*<a href={fvCtaUrl} className={"c-heroHeader__cta__btn"}>*/}
            {/*  <span className={"c-heroHeader__cta__btn__txt1"}>*/}
            {/*    ONLINE無料肌診断はこちら*/}
            {/*  </span>*/}
            {/*  <span className={"c-heroHeader__cta__btn__arrow"}></span>*/}
            {/*</a>*/}
            {/*<div className={"c-heroHeader__cta__login"}>*/}
            {/*  既にご登録のお客様は<a href={loginUrl}>ログイン</a>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="">
          {/*<T_TOP_BANNER quizUrl={quizUrl} />*/}
          <div id={"float-start"}></div>
          <T_TOP_CONCEPT />
          <T_TOP_HOWIT />
          <T_TOP_PRODUCTS />
          {/* <T_TOP_METHOD /> */}
          {/* <T_TOP_EYECREAM /> */}
          {/* <T_TOP_INTERVIEW /> */}
          {/* <T_TOP_VISIT /> */}
          {/*<T_TOP_MEDIA />*/}
          <hr className="p-hr" />
          <T_TOP_INFO_WP posts={data} />
          <div className="t-top__footer-images">
            <div className="t-top__footer-images__left">
              <picture>
                <source
                  media="(max-width:959px)"
                  srcSet={"/images/top/pc/bottom_01.jpg?v=202107261300"}
                />
                <img
                  src={"/images/top/pc/bottom_01.jpg?v=202107261300"}
                  alt=""
                />
              </picture>
            </div>
            <div className="t-top__footer-images__right">
              <picture className="t-top__footer-images__right__top">
                <source
                  media="(max-width:959px)"
                  srcSet={"/images/top/pc/bottom_02.jpg?v=202107261300"}
                />
                <img
                  src={"/images/top/pc/bottom_02.jpg?v=202107261300"}
                  alt=""
                />
              </picture>
              <picture className="t-top__footer-images__right__bottom">
                <source
                  media="(max-width:959px)"
                  srcSet={"/images/top/pc/bottom_03.jpg?v=202107261300"}
                />
                <img
                  src={"/images/top/pc/bottom_03.jpg?v=202107261300"}
                  alt=""
                />
              </picture>
            </div>
            <p className="t-top__footer-images__caption">
              CUSTOMIZE FOR
              <br />
              YOUR SPECIAL SHINING GLOW...
            </p>
          </div>
          <div id={"float-end"}></div>
        </div>
      </div>
      <div id={"js-cta-float"} className={"t-top__float__btn"}>
        <a href={fvCtaUrl}>
          <img src={"/images/top/sp/float_btn.png?v=20222024101"} alt="" />
        </a>
        <span className={"t-top__float__btn__shine"}></span>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(limit: 5) {
      edges {
        node {
          id
          title
          date(formatString: "YYYY/MM/DD")
          wordpress_id
          slug
          categories {
            name
          }
        }
      }
    }
  }
`
