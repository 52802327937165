import React from "react";

import howit from '../pug/_object/_project/_top/_t-top__howit.pug';

class T_top_howit extends React.Component {
    render() {
        return howit.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_top_howit