import React from "react"
import { Link } from "gatsby"
import T_TOP_INFO_WP_LI from "../components/t_top_info_wp_li";

const T_top_info_wp = (data) => {
  return (
    <section className="t-top__info" id="information">
      <div className="t-top__info__inner">
        <h2 className="p-title p-title--section-small">NEWS</h2>
        <div className="t-top__info__inner__btn c-grid__col">
          <Link className="button" to="/news"><span></span>ALL NEWS<span>➝</span></Link>
        </div>
        <div className="c-grid__col">
          <ul className="p-updateList">
            <hr />
            {data.posts.allWordpressPost.edges.map( ({node}) => (
              <T_TOP_INFO_WP_LI key={node.id} title={node.title} date={node.date} wp_id={node.wordpress_id} slug={node.slug} category={node.categories[0].name}/>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
export default T_top_info_wp
