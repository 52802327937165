import React from "react";
import {Link} from "gatsby";

class T_top_products extends React.Component {

    render() {
        return (
            <section className="t-top__products" id="products">
                <h2 className="p-title p-title--section p-title--white-shadow">PRODUCTS</h2>
                <div style={{position: "relative"}}>
                    <div className="t-top__products__images">
                        <div>
                            <Link to="/about/lm/">
                                <picture>
                                    <source media="(max-width:959px)" srcSet={"/images/top/pc/products_01.jpg?v=202107261300"} />
                                    <img className="js__kv__sld" src={"/images/top/pc/products_01.jpg?v=202107261300"} alt="" />
                                </picture>
                                <h3>ローション・モイスチャライザーセット</h3>
                                <h4>LOTION&MOISTURIZER SET</h4>
                            </Link>
                        </div>
                        <div>
                            <Link to="/about/cleansing/">
                                <picture>
                                    <source media="(max-width:959px)" srcSet={"/images/top/pc/products_05.jpg?v=202107261300"} />
                                    <img className="js__kv__sld" src={"/images/top/pc/products_05.jpg?v=202107261300"} alt="" />
                                </picture>
                                <h3>クレンジング</h3>
                                <h4>CLEANSING</h4>
                            </Link>
                        </div>
                        <div>
                            <Link to="/about/treatmentmask/">
                                <picture>
                                    <source media="(max-width:959px)" srcSet={"/images/top/pc/products_04.jpg?v=202201271700"} />
                                    <img className="js__kv__sld" src={"/images/top/pc/products_04.jpg?v=202201271700"} alt="" />
                                </picture>
                                <h3>トリートメントマスク</h3>
                                <h4>TREATMENT MASK</h4>
                            </Link>
                        </div>
                        <div></div>
                    </div>
                    <p className="t-top__products__rot-text">HOTARU LINE UP</p>
                </div>
                <div className="t-top__products__relative-bg"></div>
                <div className="t-top__products__button">
                    <Link className="button" to="/products"><span></span>ALL PRODUCTS<span>➝</span></Link>
                </div>

                {/* <div className="t-top__products__store">
                    <h2 className="p-title p-title--section p-title--white-shadow">SHOP LIST</h2>
                    <div style={{position: "relative"}}>
                        <div className="t-top__products__store__desc">
                            <picture>
                                <source media="(max-width:959px)" srcSet={"/images/stores/yurakucho/fv.jpg?v=202110071929"} />
                                <img className="js__kv__sld" src={"/images/stores/yurakucho/fv.jpg?v=202110071929"} alt="" />
                            </picture>
                            <div>
                                <h3>
                                  HOTARU PERSONALIZEDが体験できる<br />
                                  HOTARU PERSONALIZED SHOPが誕生
                                </h3>
                                <div className="t-top__products__store__button">
                                    <Link className="button" to="/store/"><span></span>SHOPを見る<span>➝</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/*<div className="t-top__products__bottom">*/}
                {/*    <div className="t-top__products__bottom__left">*/}
                {/*        <div className="t-top__products__bottom__left__inner">*/}
                {/*            <h3>HOTARU</h3>*/}
                {/*            <h2>MEDICAL<br />CARE</h2>*/}
                {/*            <p>何度も繰り返すニキビの悩みに <br />オンライン治療でニキビ治療薬を。</p>*/}
                {/*            <div className="t-top__products__bottom__left__inner__button">*/}
                {/*                /!*<a className="button" href={""}><span></span>WEB SITE<span>➝</span></a>*!/*/}
                {/*                <a className="button medical-care-btn" href={"https://store.medulla.co.jp/lp/hmc-specialcontents?utm_source=bland&utm_medium=top"}>VIEW MORE</a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="t-top__products__bottom__left__inner__absolute">*/}
                {/*            REMOTE CARE*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <picture className="t-top__products__bottom__right">*/}
                {/*        <source media="(max-width:959px)" srcSet={"/images/top/pc/medicalcare.jpg?v=202108022330"} />*/}
                {/*        <img className="js__kv__sld" src={"/images/top/pc/medicalcare.jpg?v=202108022330"} alt="" />*/}
                {/*    </picture>*/}
                {/*</div>*/}
          </section>
        )
    }
}
export default T_top_products
