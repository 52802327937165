import React from "react";
import {Link} from "gatsby";

// import concept from '../pug/_object/_project/_top/_t-top__concept.pug';

class T_top_concept extends React.Component {
    render() {
        // return concept.call(this, {        // ← use transpiled function
        //     // // variables
        //     // items,
        //     // period,
        //     // // components
        //     // ItemDetail,
        // });
        return (
          <section className="t-top__concept" id="concept">
              <div className="t-top__concept__left">
                  <picture className="t-top__concept__left__bg">
                      <source media="(max-width:959px)" srcSet={"/images/top/pc/initial_logo.png"} />
                      <img src={"/images/top/pc/initial_logo.png"} />
                  </picture>
                  <div className="t-top__concept__left__desc">
                      <h2 className="p-title p-title--section">CONCEPT</h2>
                      <p>HOTARU PERSONAILZEDは、<br />
                          オンラインを通じて一人ひとりの中に眠る<br />
                          美しさの可能性を引き出す<br />
                          パーソナライズビューティーブランドです。<br />
                          <br />
                          オンラインであなたの美肌習慣レベルを診断。<br />
                          一人ひとりの課題に合わせて<br />
                          あなたの魅力を最大化するための<br />
                          パーソナルプログラムを提案します。<br />
                          <br />
                          プログラムを終えたとき、<br />
                          あなたが自分自身を<br />
                          心から愛しく思えますように。<br />
                          自分の美の可能性を信じるあなたを、<br />
                          HOTARU PERSONAILZEDは<br />
                          全力でサポートします。
                      </p>
                      <Link className="button" to="/about/hotaru/"><span></span>ABOUT<span>➝</span></Link>
                  </div>
              </div>
              <div className="t-top__concept__right">
                  <picture>
                      <source media="(max-width:959px)" srcSet={"/images/top/pc/concept_01.jpg?v=202107261300"} />
                      <img src={"/images/top/pc/concept_01.jpg?v=202107261300"} />
                      <h3>PERSONALIZED SKIN CARE</h3>
                  </picture>
              </div>
              <div className="t-top__concept__img_rule">
                <picture className="t-top__concept__img_rule__left">
                    <source media="(max-width:959px)" srcSet={"/images/top/pc/concept_04.jpg?v=202107261300"} />
                    <img src={"/images/top/pc/concept_04.jpg?v=202107261300"} />
                </picture>
                <picture className="t-top__concept__img_rule__center">
                    <source media="(max-width:959px)" srcSet={"/images/top/pc/concept_02.jpg?v=202107261300"} />
                    <img src={"/images/top/pc/concept_02.jpg?v=202107261300"} />
                </picture>
                <picture className="t-top__concept__img_rule__right">
                    <source media="(max-width:959px)" srcSet={"/images/top/pc/concept_03.jpg?v=202107261300"} />
                    <img src={"/images/top/pc/concept_03.jpg?v=202107261300"} />
                </picture>
              </div>
          </section>
        )
    }
}
export default T_top_concept
