import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import L_header from "./l_header"

// import banner from '../pug/_object/_project/_top/_t-top__banner.pug';

const assetVersion = "?v=2202281721"

class T_top_banner extends React.Component {
  render() {
    const lpUrl =
      process.env.CART_SITE_URL +
      "/lp/h-specialcontents_lis_2178?ac=h_2178_lm_0041&mc=of&argument=rVHtCJXQ&dmai=official_LP"
    return (
      <section className="t-top__banner" id="banner">
        <div className={"t-top__banner__inner"}>
          <a href={"/lp/cm_2022_winter/"}>
            <img
              className=""
              src={"/images/top/pc/banner_04.jpg" + assetVersion}
              alt=""
            />
          </a>
        </div>
      </section>
    )
  }
}
T_top_banner.propTypes = {
  quizUrl: PropTypes.string,
}

T_top_banner.defaultProps = {
  quizUrl: ``,
}
export default T_top_banner
