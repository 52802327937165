import React from 'react';

export default function() {
  return (
    <section className="t-top__howit" id="how-it-works">
      <div className="p-howItSteps js-howItSteps" data-length={5}>
        <div className="p-howItSteps__container js-howItSteps__container">
          <div className="p-howItSteps__container__middle">
            <h2 className="p-title p-title--section">HOW IT WORKS</h2>
            <p className="p-howItSteps__container__middle__absolute">ONLINE COUNSELING</p>
            <div className="p-howItSteps__flex3">
              <ul className="p-howItSteps__flex3__indicator">
                <div className="c-verticalSteps">
                  <div className="c-verticalSteps__indicator">
                    <div className="c-verticalSteps__indicator__progress js-howItSteps__progress"></div>
                    <ul className="c-verticalSteps__nums js-howItSteps__list">
                      <li className="is-active">
                        <span>01</span>
                      </li>
                      <li>
                        <span>02</span>
                      </li>
                      <li>
                        <span>03</span>
                      </li>
                      <li>
                        <span>04</span>
                      </li>
                      <li>
                        <span>05</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
              <div className="p-howItSteps__flex3__center">
                <ul className="p-howItSteps__images js-howItSteps__list">
                  <li className="is-active">
                    <img src="/images/top/pc/howitworks_01.png?v=202107261300" alt="" />
                  </li>
                  <li>
                    <img src="/images/top/pc/howitworks_02.png?v=202107261300" alt="" />
                  </li>
                  <li>
                    <img src="/images/top/pc/howitworks_03.png?v=202107261300" alt="" />
                  </li>
                  <li>
                    <img src="/images/top/pc/howitworks_04.png?v=202107261300" alt="" />
                  </li>
                  <li>
                    <img src="/images/top/pc/howitworks_05.png?v=202107261300" alt="" />
                  </li>
                </ul>
              </div>
              <div className="p-howItSteps__flex3__desc">
                <ul className="p-howItSteps__details js-howItSteps__list">
                  <li className="is-active">
                    <div className="p-howItSteps__details__step">
                      <span>Step</span>
                    </div>
                    <div className="p-howItSteps__details__num">
                      <span>01</span>
                    </div>
                    <div className="p-howItSteps__details__txt">
                      <h3 className="p-howItSteps__details__ttl">パーソナライズ診断</h3>
                      <p>
                        オンライン診断で肌の状態と
                        <br />
                        スキンケア習慣をチェック
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="p-howItSteps__details__step">
                      <span>Step</span>
                    </div>
                    <div className="p-howItSteps__details__num">
                      <span>02</span>
                    </div>
                    <div className="p-howItSteps__details__txt">
                      <h3 className="p-howItSteps__details__ttl">工場で製造</h3>
                      <p>
                        診断結果によってあなたに
                        <br />
                        ぴったりのスキンケア改善
                        <br />
                        プランを提案
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="p-howItSteps__details__step">
                      <span>Step</span>
                    </div>
                    <div className="p-howItSteps__details__num">
                      <span>03</span>
                    </div>
                    <div className="p-howItSteps__details__txt">
                      <h3 className="p-howItSteps__details__ttl">あなたの元にお届け</h3>
                      <p>
                        肌に合わせて、
                        <br />
                        あなた専用のスキンケア
                        <br />
                        アイテムも調合
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="p-howItSteps__details__step">
                      <span>Step</span>
                    </div>
                    <div className="p-howItSteps__details__num">
                      <span>04</span>
                    </div>
                    <div className="p-howItSteps__details__txt">
                      <h3 className="p-howItSteps__details__ttl">カウンセリング</h3>
                      <p>
                        スキンケア改善プランを
                        <br />
                        実践しながら
                        <br />
                        肌状態と習慣を記録
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="p-howItSteps__details__step">
                      <span>Step</span>
                    </div>
                    <div className="p-howItSteps__details__num">
                      <span>05</span>
                    </div>
                    <div className="p-howItSteps__details__txt">
                      <h3 className="p-howItSteps__details__ttl">スキンケアセットの変更</h3>
                      <p>
                        記録に合わせて、
                        <br />
                        次のスキンケアアイテムを変更
                        <br />
                        （変更をしないことも可能です）
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
