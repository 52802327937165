import React from "react"
import {Link} from "gatsby";

const T_top_info_wp_li = (data) => {
  return (
    <>
      <li id={data.id}>

        <Link to={"/news/" + data.wp_id}>
          <span className="p-updateList__date">{data.date}</span>
          <span className="p-updateList__cat">{data.category}</span>
          <h3 className="p-updateList__ttl">{data.title}</h3>
        </Link>

      </li>
      <hr />
    </>
  )
}
export default T_top_info_wp_li
